import { DictionaryActions } from '../actions';

const INITIAL_STATE = {
    country: {},
    industry: {},   
    model_type: {},
    price_units: {},
    solution_type: {},
    isFetching: false,
    isFetched: false,
    error: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DictionaryActions.FETCH_DICTIONARY_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case DictionaryActions.FETCH_DICTIONARY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                error: false,
                ...action.payload
            };
        case DictionaryActions.FETCH_DICTIONARY_ERROR:
            return {
                ...state,
                isFetching: false,
                isFetched: false,
                error: action.payload
            };
        case DictionaryActions.FETCH_MODELS_RESET:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};