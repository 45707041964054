import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from "redux-thunk";
import logger from "redux-logger";
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';

import rootReducer from './services/reducers';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

const middleware = process.env.NODE_ENV === 'production' ? [thunk] : [thunk, logger];
export const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(...middleware),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    )
);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </Router>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
