import { combineReducers } from 'redux';
import { UserActions } from '../actions';
import AccountActions from '../actions/AccountActions';

const INITIAL_USER_STATE = {
        isAuthenticated: false,
        userInfo: null,
        error: null
    }
;

const user = (state = INITIAL_USER_STATE, action) => {
    switch (action.type) {
        case UserActions.SIGN_IN:
            return {
                ...state,
                isAuthenticated: true,
                error: null,
                ...action.payload,
            };
        case UserActions.SIGN_OUT:
            return {
                ...state,
                error: null,
                isAuthenticated: false,
                userInfo: null,
            };
        case UserActions.SIGN_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

const INITIAL_PRODUCTS_STATE = {
    list: [],
    loading: false,
    error: null
};

const products = (state = INITIAL_PRODUCTS_STATE, action) => {
    switch (action.type) {
        case AccountActions.PRODUCTS_LIST_LOADING:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case AccountActions.PRODUCTS_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                list: action.payload
            };
        case AccountActions.PRODUCTS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case AccountActions.ACCOUNT_STATE_RESET:
            return {
                ...INITIAL_PRODUCTS_STATE
            };
        default:
            return state;
    }
};

export default combineReducers({
    user,
    products
});