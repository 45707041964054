export default class UserActions {
    static SIGN_IN = 'SIGN_IN';
    static SIGN_OUT = 'SIGN_OUT';
    static SIGN_ERROR = 'SIGN_ERROR';

    static signIn(userInfo) {
        return {
            type: UserActions.SIGN_IN,
            payload: userInfo
        }
    }

    static signOut() {
        return {
            type: UserActions.SIGN_OUT
        }
    }

    static signError(error) {
        return {
            type: UserActions.SIGN_ERROR,
            payload: error
        }
    }
}