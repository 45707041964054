import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import SignIn from './SignIn';
import { UserActions } from '../../../../services/actions';
import { withRouter } from 'react-router-dom';

class SignInContainer extends PureComponent {

    handleOnLogin = ({email, pass}) => {
        Auth.signIn(email, pass)
            .then(() => {
                    Auth.currentAuthenticatedUser()
                        .then((cognitoUser) => {
                            //set user to store
                            this.props.dispatch(UserActions.signIn({
                                userInfo: cognitoUser.attributes
                            }));
                        });
                    this.props.history.goBack();
                }
            )
            .catch(err => this.props.dispatch(UserActions.signError(err.message)));
    };

    render() {
        const {error} = this.props;
        return (
            <Fragment>
                {error && <p className='error-text'>{error}</p>}
                <SignIn onLogin={this.handleOnLogin}/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    error: state.account.user.error
});

export default withRouter(connect(mapStateToProps)(SignInContainer));
