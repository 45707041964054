import { ModelsActions } from '../actions';

const INITIAL_MODEL_STATE = {
    list: [],
    isFetching: false,
    isFetched: false,
    error: null
};

const models = (state = INITIAL_MODEL_STATE, action) => {
    switch (action.type) {
        case ModelsActions.FETCH_MODELS_REQUEST:
            return {
                ...state,
                isFetching: true,
                isFetched: false
            };
        case ModelsActions.FETCH_MODELS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                error: false,
                list: action.payload
            };
        case ModelsActions.FETCH_MODELS_ERROR:
            return {
                ...state,
                isFetching: false,
                idFetched: false,
                error: action.payload
            };
        case ModelsActions.FETCH_MODELS_RESET:
            return {
                ...INITIAL_MODEL_STATE
            };
        default:
            return state;
    }
};

export default models;