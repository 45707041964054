import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { SignInContainer, SignUpContainer } from './components';

import './Authentication.css';

export const AuthFormType = {
    SIGNIN: 'Sign in',
    SIGNUP: 'Sign up',
};


class Authentication extends PureComponent {
    static defaultProps = {
        type: AuthFormType.SIGNIN,
    };

    constructor() {
        super();
        this.state = {
            isAuthForm: true
        }
    }

    componentDidMount() {
        const {type} = this.props;
        this.setState({isAuthForm: type === AuthFormType.SIGNIN});
    }

    render() {
        const {type} = this.props;
        const {isAuthForm} = this.state;

        const authBottom = (
            <p className='auth-form__text'>Don't have an account? <Link
                className='auth-form__link'
                to={{
                    pathname: '/auth/signup',
                    state: {modal: true}
                }}>{AuthFormType.SIGNUP}</Link></p>
        );

        const regBottom = (
            <p className='auth-form__text'>Already have an account? <Link
                className='auth-form__link'
                to={{
                    pathname: '/auth/signin',
                    state: {modal: true}
                }}>{AuthFormType.SIGNIN}</Link></p>
        );

        return (
            <div className='auth-wrapper'>
                <h1 className='auth-form__title'>{type}</h1>
                <p className='auth-form__text'>You should be authorised to get access to insights.</p>
                <div className='auth-content'>
                    {isAuthForm ? <SignInContainer/> : <SignUpContainer/>}
                </div>
                <div className='auth-bottom'>
                    {isAuthForm ? authBottom : regBottom}
                </div>
            </div>
        );
    }
}

export default Authentication;
