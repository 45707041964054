import React, { PureComponent } from 'react';
import SignUp from './SignUp';

class SignUpContainer extends PureComponent {

    render() {
        return (
            <SignUp/>
        )
    }
}

export default SignUpContainer;
