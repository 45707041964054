import React, { PureComponent } from 'react';
import { ModelCard } from '../../../../components';

import './Products.css';

class Products extends PureComponent {
    static defaultProps = {
        products: [],
    };

    render() {
        const {products} = this.props;
        const modelsList = products.map((product, idx) => (
            <ProductModel
                key={idx}
                {...product}
            />)
        );

        return (
            <ul className='account-products-list cards-wrapper'>
                {modelsList}
            </ul>
        )
    }
}

export default Products;

function ProductModel(props) {
    const {units_spent, price_unit_quantity, price_unit_name} = props;

    const left_width = (units_spent * 100) / price_unit_quantity;
    const left_val = price_unit_quantity - units_spent;

    const style = {
        width: `${left_width}%`,
        height: '100%'
    };

    return (
        <ModelCard {...props} isInternal={false}>
            <div className='product-space'>
                <div style={style} className='product-space-left'></div>
                <div className='product-space-value'><b>{left_val} {price_unit_name} left</b></div>
            </div>
        </ModelCard>
    );
}