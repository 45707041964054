import React, {Component, Fragment} from 'react';
import './Description.css';
import Tags from '../components/Tags/Tags';
import company1 from '../img/bloomberg.svg';
import company2 from '../img/powerlytics.svg';
import company3 from '../img/snpglobal.svg';

class Description extends Component {
	render() {
		return(
		<Fragment>
			<div className="main-spot">
				<div className="description-wrapper">
					<p>Analyse The Invoices history to optimze the working capital. Identify the best matched financial advisor for your customer profile through portfolio and sentiment analysis.</p>
				</div>
				<div className="video-wrapper">
					<button className="video-play-button" type="button">
						<svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
							<circle cx="30" cy="30" r="28" fill="none" stroke="currentColor" strokeWidth="2"/>
							<path fill="currentColor" d="M46 30L20 45V15z"/>
						</svg>
						Play video
					</button>
				</div>
			</div>
			<Tags tags={this.props.tags} />
			<div className="powered-by">
				<h2 className="title">Powered by</h2>
				<ul className="companies-wrapper">
					<li className="company"><img alt="Bloomberg" src={company1} /></li>
					<li className="company"><img alt="Power Lytics" src={company2} /></li>
					<li className="company"><img alt="S&P Global" src={company3} /></li>
				</ul>
			</div>
		</Fragment>
	)}
}

export default Description;
