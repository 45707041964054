import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { NavLink, Route, Switch } from 'react-router-dom';
import { ProductsContainer } from './components';

import './Account.css';


class Account extends PureComponent {

    componentDidMount() {
        const {isAuthenticated} = this.props;
        const url = !isAuthenticated ? '/' : '/account/products';
        this.props.history.push(url);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated && !this.props.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    componentWillUnmount() {
        // todo: maybe reset account state here
    }

    render() {
        return (
            <div className="account-wrapper">
                <header className="account-header">
                    <h1 className="account-title">My account</h1>
                    <div className="toolbar-wrapper">
                        <nav className="tabs-wrapper">

                            <NavLink className="tab" activeClassName="active" to={`/account/products`}>
                                Products
                            </NavLink>
                            <NavLink className="tab" activeClassName="active" to={`/account/analytics`}>
                                Analytics
                            </NavLink>
                            <NavLink className="tab" activeClassName="active" to={`/account/settings`}>
                                Settings
                            </NavLink>
                        </nav>
                    </div>
                </header>
                <main className='account-content'>
                    <Switch>
                        <Route path={`/account/products`} component={ProductsContainer}/>
                    </Switch>
                </main>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isAuthenticated: state.account.user.isAuthenticated
});

export default connect(mapStateToProps)(Account);
