import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './logo.svg';


export default class Header extends Component {
    static defaultProps = {
        user: null,
        signOut: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            showUserMenu: false,
        };
    }

    toggleMenu = () => {
        this.setState({showUserMenu: !this.state.showUserMenu});
    };

    signOut = () => {
        this.props.signOut();
        this.setState({showUserMenu: false});
    };

    render() {
        const {user} = this.props;
        const {showUserMenu} = this.state;
        return (
            <header className="main-header">
                <Link to="/" className="main-logo"><img alt='890 Capgemini logo' src={logo}/></Link>
                <nav className="main-nav">
                    <div className="submenu">
                        <Link to="/">Marketplace</Link>
                        <nav className="submenu-nav">
                            <Link to="/exchange/insights">Insights exchange</Link>
                            <Link to="/exchange/data">Data exchange</Link>
                            <Link to="/exchange/outcomes">Outcomes exchange</Link>
                        </nav>
                    </div>
                    <Link to="/security">Security</Link>
                    <Link to="/about">About</Link>
                </nav>
                <button className="global-search" aria-label="Search" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <circle fill="currentColor" cx="12" cy="12" r="12"/>
                        <g fill="none" stroke="#FFF" strokeWidth="2" strokeLinecap="round">
                            <path d="M14 14l3 3"/>
                            <circle cx="11" cy="11" r="4"/>
                        </g>
                    </svg>
                </button>

                {user.isAuthenticated
                    ?
                    <div className="user-info">
                        <button className="username" type='button' onClick={this.toggleMenu}>{user.userInfo.email}</button>
                        {showUserMenu && <ul className='user-menu'>
                            <li className='user-menu__item'>
                                <Link className='user-menu__button' to="/account/" onClick={this.toggleMenu}>Account</Link>
                            </li>
                            <li className='user-menu__item'>
                                <button type='button' className='user-menu__button' onClick={this.signOut}>Sign out
                                </button>
                            </li>
                        </ul>}
                    </div>
                    :
                    <Link to={{
                        pathname: '/auth/signin',
                        state: {modal: true}
                    }} className="sign-in">
                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" strokeWidth="2"
                                  d="M6 8l7 4-7 4V8zm0-3V1h9a5 5 0 0 1 5 5v12a5 5 0 0 1-5 5H6v-4"/>
                        </svg>
                        Sign in
                    </Link>
                }

            </header>
        )
    }
}
