import React, { PureComponent, Fragment } from 'react';

class SignUp extends PureComponent {

    render() {
        return (
            <Fragment>
                SignUp
            </Fragment>
        )
    }
}

export default SignUp;
