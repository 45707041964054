import React, { Fragment, Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Amplify, { Auth } from 'aws-amplify';

import { Home, ModelContainer, Industry, Authentication, Exchange, Account } from './scenes'
import { Header, ModalWindow } from './components';
import { UserActions, DictionaryActions, ModelsActions } from './services/actions';

import { AuthFormType } from './scenes/Authentication/Authentication';

import './App.css';

// init Amplify config
import aws_exports from './services/aws-exports';

Amplify.configure(aws_exports);


class App extends Component {
    previousLocation = this.props.location;

    state = {renderApp: false};

    componentWillMount() {
        // check if we have been already login
        Auth.currentAuthenticatedUser().catch(error => {})
            .then(cognitoUser => {
                if (cognitoUser) {
                    this.props.signIn({userInfo: cognitoUser.attributes});
                }
                // todo: for tests
                // const user = await Auth.currentAuthenticatedUser();
                // console.log(user.signInUserSession.idToken.jwtToken);
                // console.log('---');
                // console.log(user.signInUserSession.accessToken.jwtToken);
                this.setState({renderApp: true})
            });

        this.props.fetchDictionary();
        this.props.fetchModels();
    }

    componentWillUpdate(nextProps) {

        let {location} = this.props;

        if (
            nextProps.history.action !== 'POP' &&
            (!location.state || !location.state.modal)
        ) {
            this.previousLocation = this.props.location;
        }
    }

    handleSignOut = () => {
        Auth.signOut()
            .then(() => {
                this.props.signOut();
            })
            .catch(err => console.log(err));
    };

    render() {
        let {location, user} = this.props;

        let isModal = !!(
            location.state &&
            location.state.modal &&
            this.previousLocation !== location
        );
        if (!this.props.isModelsFetched || !this.props.isDictionaryFetched || !this.state.renderApp)
            return (
                <Fragment>
                    <div style={{
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '30px'
                    }}>Loading...
                    </div>
                </Fragment>);
        return (
            <Fragment>
                <Header user={user} signOut={this.handleSignOut}/>
                <Switch location={isModal ? this.previousLocation : location}>
                    <Route exact path="/" component={Home}/>
                    <Route path="/model/:id" component={ModelContainer}/>
                    <Route path="/industry/:id" component={Industry}/>
                    <Route path="/exchange/:id" component={Exchange}/>
                    <Route path="/account/" component={Account}/>
                    <Route render={() => <h1>404 page</h1>}/>
                </Switch>

                {isModal ? <Switch>
                    <Route path="/auth/signin" component={AuthWrapper(AuthFormType.SIGNIN)}/>
                    <Route path="/auth/signup" component={AuthWrapper(AuthFormType.SIGNUP)}/>/>
                </Switch> : null}
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.account.user,
    isModelsFetched: state.dictionary.isFetched,
    isDictionaryFetched: state.models.isFetched
});

export default withRouter(
    connect(mapStateToProps, {
        fetchDictionary: DictionaryActions.fetchDictionary,
        fetchModels: ModelsActions.fetchModels,
        signIn: UserActions.signIn,
        signOut: UserActions.signOut
    })(App));

const AuthWrapper = (FormType) => {
    class HOC extends React.Component {
        closeWin = () => {
            this.props.history.goBack();
        };

        render() {
            return (
                <ModalWindow onClose={this.closeWin}>
                    <Authentication/>
                </ModalWindow>
            );
        }
    }

    return HOC;
};
