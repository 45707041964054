import React, {Component} from 'react';
import {Link} from "react-router-dom";
import cn from "class-names";
import './IndustrySelector.css';
import connect from "react-redux/es/connect/connect";

class IndustrySelector extends Component {
    static defaultProps = {
        label: "Choose your industry",
        openUp: true
    };
    state = {isOpen: false};
    toggleList = (e) => {
        let {isOpen} = this.state;
        if (!isOpen) {
            document.addEventListener('click', this.toggleList);
        } else {
            document.removeEventListener('click', this.toggleList);
        }
        this.setState({isOpen: !isOpen});
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.toggleList);
    }

    render() {
        let {label} = this.props;
        return (
            <div className="industry-selector-wrapper">
                <button className="toggle" type="button" onClick={this.toggleList}>{label}</button>
                <ul className={cn({list: true, show: this.state.isOpen, top: this.props.openUp})}>
                    {this.props.industries.map(e=>(
                        <li key={e.id} >
                            <Link to={`/industry/${e.id}`} className="option">{e.name}</Link>
                        </li>
                    ))}
                    <li><Link to="/industries" className="option more">See more</Link></li>
                </ul>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        industries: state.dictionary.industry
    }
}

export default connect(mapStateToProps)(IndustrySelector);
