import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {IndustrySelector, ModelCard} from '../../components';
import "./Exchange.css";
import insightsImg from '../../img/exchange/slide-1.jpg';
import dataImg from '../../img/exchange/slide-2.jpg';
import outcomesImg from '../../img/exchange/slide-3.jpg';

const exchanges = {
	insights: {
		name: "Insights Exchange",
		color: "#508ded",
		img: insightsImg
	},
	data: {
		name: "Data Exchange",
		color: "#5f1478",
		img: dataImg
	},
	outcomes: {
		name: "Outcomes Exchange",
		color: "#aa0000",
		img: outcomesImg
	}
}

class Exchange extends Component {
	static defaultProps = {
		id: 0
	}
	getCards() {
		let colors = [
			"#507FFB",
			"#9171FB",
			"#DE507A",
			"#74B184",
			"#5C969C",
			"#DF5797",
			"#5E5097",
			"#9A5E9D",
			"#E69143",
			"#73CC8B",
			"#F2CF34",
			"#E2771C",
			"#3A60CA",
			"#EEBC3E",
			"#CF0141",
			"#AA2959",
			"#A5888E",
			"#79D69C",
		]
		let models = [
			{
				name: "Account Receivable",
				description: "Analyse The Invoices history to optimze the working capital"
			},
			{
				name: "Sentiment Score",
				description: "Build your portfolio and control is perfomance based on sentiment analysis"
			},
			{
				name: "Call Center Monitoring",
				description: "Analyze the Calls recording, monitor the call center KPI"
			}
		]
		for (let i=0; i<2; i++) {
			models.push(...models);
		}
		let cards = models.map((model, id) => (
			<ModelCard
				key={id}
				name={model.name}
				description={model.description}
				color={colors[Math.floor(Math.random() * colors.length)]}
			/>)
		);
		return cards;
	}

	render() {
		let id = this.props.match.params.id;
		return(
			<div className="exchange-wrapper">
				<div className="main-spot" style={{
					backgroundColor: exchanges[id].color,
					backgroundImage: `url(${exchanges[id].img})`
				}}>
					<ul className="breadcrumbs">
						<li className="step"><Link to="/">Marketplace</Link></li>
						<li className="step">{exchanges[id].name}</li>
					</ul>
					<h1 className="main-title">{exchanges[id].name}</h1>
				</div>
				<div className="description">
					<div className="intro">
						<h2>Insights to power your business</h2>
						<p>Access and combine trusted and curated datasets from an extensive ecosystem of industry speciﬁc, open and exclusive sources. Rapidly and securely connect your d  ata to build  data-driven solutions and generate insights.</p>
					</div>
					<div className="details">
						<h3>Data-based business insights</h3>
						<p>Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Vestibulum fringilla pede sit amet augue. In turpis. Pellentesque posuere. Praesent turpis. Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc, eu sollicitudin urna dolor sagittis lacus.</p>
						<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut non enim eleifend felis pretium feugiat. Vivamus quis mi. Phasellus a est. Phasellus magna. In hac habitasse platea dictumst. Curabitur at lacus ac velit ornare lobortis. Curabitur a felis in nunc fringilla tristique. Morbi mattis ullamcorper velit Phasellus gravida semper nisi. Nullam vel sem. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam. Sed hendrerit.</p>
						<p>Morbi ac felis. Nunc egestas, augue at pellentesque laoreet, felis eros vehicula leo, at malesuada velit leo quis pede. Donec interdum, metus et hendrerit aliquet, dolor diam sagittis ligula, eget egestas libero turpis vel mi.</p>
						<p>Nunc nulla. Fusce risus nisl, viverra et, tempor et, pretium in, sapien. Donec venenatis vulputate lorem. Morbi nec metus. Phasellus blandit leo ut odio. Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem.</p>
					</div>
					<IndustrySelector openUp={true} />
				</div>
				<section className="products-wrapper">
					<h1 className="main-title">Popular products</h1>
					<div className="cards-wrapper">
						{this.getCards()}
					</div>
				</section>
			</div>
		)
	}
}

export default Exchange;
