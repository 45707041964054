import { Auth } from 'aws-amplify';
import { API_URL } from './constants/endpoints';

const aws_exports = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:120ea8be-1e60-48af-8a74-2a84304e4a46',
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_tdxmz4YkE',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '72tehkmajo7fm25km46l5qs96r'
    },

    API: {
        endpoints: [
            {
                name: 'getUserProducts',
                endpoint: API_URL.models,
                custom_header: async () => {
                    const user = (await Auth.currentSession());
                    const userToken = user.getIdToken().getJwtToken();
                    const accessToken = user.getAccessToken().getJwtToken();
                    return {
                        Authorization: userToken,
                        AccessToken: accessToken
                    }
                }
            },
            {
                name: 'buyModel',
                endpoint: API_URL.buy_model,
                custom_header: async () => {
                    const user = (await Auth.currentSession());
                    const userToken = user.getIdToken().getJwtToken();
                    const accessToken = user.getAccessToken().getJwtToken();
                    return {
                        Authorization: userToken,
                        AccessToken: accessToken
                    }
                }
            }
        ]
    }
};

export default aws_exports;