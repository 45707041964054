import axios from 'axios'

import { API_URL } from '../constants/endpoints';

export default class DictionaryActions {
    static FETCH_DICTIONARY_REQUEST = 'FETCH_DICTIONARY_REQUEST';
    static FETCH_DICTIONARY_SUCCESS = 'FETCH_DICTIONARY_SUCCESS';
    static FETCH_DICTIONARY_ERROR = 'FETCH_DICTIONARY_ERROR ';

    static fetchDictionary() {
        return dispatch => {
            dispatch({type: DictionaryActions.FETCH_DICTIONARY_REQUEST});
            
            return axios.get(API_URL.dictionary)
            .then(response => dispatch({type: DictionaryActions.FETCH_DICTIONARY_SUCCESS, payload: response.data}))
            .catch(err => dispatch({type: DictionaryActions.FETCH_DICTIONARY_ERROR, payload: err.message}));
        }
    }
}