import React, { PureComponent } from 'react';
import './ModalWindow.css';
import { Overlay } from '../index';

class ModalWindow extends PureComponent {
    static defaultProps = {
        onClose: () => {}
    };

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }


    render() {

        return (
            <Overlay onClose={this.props.onClose}>
                <div className='modal-content round-content-block'>
                    {this.props.children}
                </div>
            </Overlay>
        );
    }
}

export default ModalWindow;
