import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Slider from 'rc-slider';
import connect from 'react-redux/es/connect/connect';
import 'rc-slider/assets/index.css';
import './ModelBuy.css';
import ModelsActions from '../../../../services/actions/ModelsActions';

class ModelBuy extends Component {
    static defaultProps = {
        values: [
            {
                label: '5 Invoices',
                price: '$1000'
            },
            {
                label: '20 Invoices',
                price: '$2000'
            },
            {
                label: '200 Invoices',
                price: '$15 000'
            },
            {
                label: '1000 Invoices',
                price: '$30 000'
            },
            {
                label: '10000 Invoices',
                price: '$50 000'
            }]
    };

    state = {
        valueID: 0,
        error: null
    };

    increaseSliderValue = () => {
        let {valueID} = this.state;
        let newValue = Math.min(++valueID, this.props.values.length - 1);
        this.setState({valueID: newValue})
    };
    decreaseSliderValue = () => {
        let {valueID} = this.state;
        let newValue = Math.max(--valueID, 0);
        this.setState({valueID: newValue})
    };
    onSliderChange = (valueID) => {
        this.setState({valueID})
    };


    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.props.isAuthenticated) {
            this.props.history.push({
                pathname: '/auth/signin',
                state: {modal: true}
            });
        } else {
            this.props.dispatch(ModelsActions.buyModel()).then(() => {
                this.props.history.push({
                    pathname: '/account'
                });
            }).catch(err => {
                this.setState({error: err.message});
            });

        }
    };

    render() {
        let {match, name, isAuthenticated} = this.props;
        let {error} = this.state;

        return (
            <div className="model-buy-wrapper">
                <ul className="breadcrumbs">
                    <li className="step"><Link to="/">Marketplace</Link></li>
                    <li className="step"><Link to="/">Insights exchange</Link></li>
                    <li className="step"><Link to={match.url}>{name}</Link></li>
                    <li className="step">Get access</li>
                </ul>
                <main className="main-content-wrapper">
                    <h1 className="main-title">
                        <span className="title">Get access to</span>
                        <span className="name">{name}</span>
                    </h1>
                    <p className="disclaimer">The model is licensed on per use basis. You will be invoiced monthly
                        based on amount of requests processed.</p>
                    <form className="form-wrapper">
                        <svg className="image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 190">
                            <g fill="none" fillRule="evenodd">
                                <path fill="#B8D9FF"
                                      d="M190.5 140.6c21.9-.6 39.3-7.8 39.3-16.5v-20.7-20.6c0-8.8-17.3-15.9-39.3-16.5v74.3z"/>
                                <path stroke="#82BAFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                                      d="M226.1 72.4c2.4 2.2 3.7 4.7 3.7 7.3 0 9.2-16.7 16.8-37.9 17.4M220.8 68.7l1.2.6M192 62.3c8.9.3 17 1.8 23.4 4.1M192 118.8c21.1-.6 37.8-8.2 37.9-17.4M229.8 80v-.3"/>
                                <path stroke="#82BAFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                                      d="M192 140.6c21.1-.6 37.9-8.2 37.9-17.4v-21.8-9.3"/>
                                <g>
                                    <path fill="#B8D9FF"
                                          d="M84 125c-17.8-.5-32-6.2-32-13.1V95.5 79c0-7 14-12.6 32-13.1v59z"/>
                                    <path stroke="#82BAFF" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="3"
                                          d="M55 70c-2 1.8-3 3.8-3 6 0 7.4 13.7 13.5 31 14M59 67.2l-1 .6M82 62a70 70 0 0 0-19 3M83 107c-17.3-.5-31-6.6-31-14M51.5 76.7v-.3"/>
                                    <path stroke="#82BAFF" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="3" d="M83 125c-17.3-.5-31-6.6-31-14V93.5 86"/>
                                </g>
                                <g transform="translate(86 42)">
                                    <path fill="#FFF"
                                          d="M.8 53.1V21.9C.8 10 23.3.4 51 .4c27.7 0 50.2 9.6 50.2 21.5v77.2c0 11.9-22.5 21.5-50.2 21.5-20.3 0-37.7-5.1-45.7-12.5-2.9-2.7-4.6-5.8-4.6-9V68.2"/>
                                    <path fill="#99C7FF"
                                          d="M101.3 26.4v72.7c0 11.9-22.5 21.5-50.2 21.5-20.3 0-37.7-5.1-45.7-12.5V26.5C5.4 15.2 26.9 6 53.4 6c26.4-.1 47.9 9.1 47.9 20.4z"/>
                                    <path fill="#DDECFE"
                                          d="M101.3 26.4v1c-8.1 9.4-27.2 16.1-49.5 16.1-19.8 0-37-5.2-46.3-12.9v-4.1C5.5 15.2 27 6 53.5 6c26.3-.1 47.8 9.1 47.8 20.4z"/>
                                    <path stroke="#1676EE" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="3"
                                          d="M93.8 10.6c4.8 3.3 7.5 7.2 7.5 11.3 0 11.9-22.5 21.5-50.2 21.5a92.5 92.5 0 0 1-37-6.9M84.8 6l.4.1M.8 21.6C1.1 9.8 23.5.3 51 .3 61.5.3 71.2 1.7 79.2 4M7.5 32.7l-2.7-2.3M101.3 72.1c0 11.9-22.5 21.5-50.2 21.5C23.4 93.6.9 84 .9 72.1M44.5 68.4C19.9 67 .9 58 .9 47.1M85.9 62.5c-7.8 3.2-18 5.4-29.3 5.9M101.3 47c0 4.1-2.7 7.9-7.3 11.2"/>
                                    <path stroke="#1676EE" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="3"
                                          d="M35.6 119.5c4.9.7 10.1 1 15.5 1 27.7 0 50.2-9.6 50.2-21.5V26.3v-4.5M18.9 115.6l3.4 1.1M.8 66.9V99c0 3.2 1.6 6.3 4.6 9 2 1.9 4.6 3.6 7.8 5.1M.8 21.9v24.5"/>
                                    <ellipse cx="23.2" cy="52.5" fill="#6BADFF" rx="3.6" ry="6.3"
                                             transform="rotate(-76 23.2 52.5)"/>
                                    <ellipse cx="23.2" cy="77.6" fill="#6BADFF" rx="3.6" ry="6.3"
                                             transform="rotate(-76 23.2 77.6)"/>
                                    <ellipse cx="23.2" cy="104.5" fill="#6BADFF" rx="3.6" ry="6.3"
                                             transform="rotate(-76 23.2 104.5)"/>
                                </g>
                                <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                                   transform="translate(34 7)">
                                    <path stroke="#1676EE" d="M8.3 158h24.8M27.3 152l5.8 6-5.8 6.2"/>
                                    <circle cx="4" cy="129" r="4" stroke="#82BAFF"/>
                                    <circle cx="157.5" cy="7.5" r="7.5" stroke="#82BAFF"/>
                                    <circle cx="164" cy="31" r="4" stroke="#82BAFF"/>
                                    <path stroke="#82BAFF" d="M33.3 32l7.2 4M30.3 11l15.2 16M49.3 12l4.2 9"/>
                                    <path stroke="#1676EE" d="M201.7 23h-24.8M182.7 17l-5.8 6 5.8 6.2"/>
                                </g>
                            </g>
                        </svg>
                        <label className="field-label">What amount of data you want to process?</label>
                        <div className="slider-wrapper">
                            <button className="control" type="button" onClick={this.decreaseSliderValue}>
                                <svg viewBox="0 0 32 32">
                                    <path strokeWidth="2" stroke="currentColor" d="M9,16h14"/>
                                </svg>
                            </button>
                            <Slider
                                className="slider"
                                onChange={this.onSliderChange}
                                value={this.state.valueID}
                                min={0}
                                max={4}
                                dots={true}
                                tabIndex={0}
                                handle={(props) => {
                                    return (
                                        <div tabIndex={props.tabIndex}
                                             style={{...props.style.main, left: `${props.offset}%`}}>
                                            <span style={props.style.label}>{this.props.values[props.value].label}</span>
                                        </div>
                                    )
                                }}
                                trackStyle={{
                                    height: '12px',
                                    borderRadius: '12px',
                                    background: 'var(--accentColor)',
                                    margin: '0 -6px',
                                    top: '10px',
                                }}
                                railStyle={{
                                    height: '12px',
                                    borderRadius: '12px',
                                    margin: '0 -6px',
                                    top: '10px',
                                    width: 'calc(100% + 12px)',
                                }}
                                dotStyle={{
                                    width: '2px',
                                    height: '5px',
                                    background: '#9BA2BB',
                                    border: 'none',
                                    bottom: '100%',
                                    margin: '0 0 4px 0'
                                }}
                                handleStyle={{
                                    main: {
                                        borderRadius: '32px',
                                        height: '32px',
                                        width: '32px',
                                        border: '2px solid var(--accentColor)',
                                        background: 'var(--accentColor)',
                                        boxShadow: '0 0 0 7px white inset',
                                        margin: '-10px 0 0 -16px',
                                        position: 'relative',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        userSelect: 'none'
                                    },
                                    label: {
                                        position: 'absolute',
                                        width: '100px',
                                        left: '50%',
                                        top: '100%',
                                        margin: '0.5em 0 0 -50px',
                                        fontSize: '1em',
                                        lineheight: '1',
                                        fontWeight: '600',
                                        textAlign: 'center'
                                    }
                                }}
                            />
                            <button className="control" type="button" onClick={this.increaseSliderValue}>
                                <svg viewBox="0 0 32 32">
                                    <path strokeWidth="2" stroke="currentColor" d="M9,16h14M16,9v14"/>
                                </svg>
                            </button>
                        </div>
                        {error && <p className='error-text'>{error}</p>}
                    </form>
                    <div className="total-wrapper">
                        <p className="total">
                            Total amount: <span className="value">{this.props.values[this.state.valueID].price}</span>
                        </p>
                        <button type='button' onClick={this.handleSubmit}
                                className="submit">{!isAuthenticated ? 'Continue' : 'Approve'}</button>
                    </div>
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.account.user.isAuthenticated
});

export default withRouter(connect(mapStateToProps)(ModelBuy));
