import React, { PureComponent } from 'react';

class SignIn extends PureComponent {
    static defaultProps = {
        onLogin: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            pass: ''
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {email, pass} = this.state;
        this.props.onLogin({email, pass});
    };

    render() {
        const {email, pass} = this.state;
        return (
            <form className='auth-form' onSubmit={this.handleSubmit}>
                <fieldset className='auth-form__block'>
                    <label htmlFor="signinEmail" className='auth-form__label'>Email</label>
                    <input
                        className='auth-form__input'
						id="signinEmail"
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.handleInputChange}/>
                </fieldset>
                <fieldset className='auth-form__block'>
                    <label htmlFor="signinPass" className='auth-form__label'>Password</label>
                    <input
                        className='auth-form__input'
						id="signinPass"
                        name="pass"
                        type="password"
                        value={pass}
                        onChange={this.handleInputChange}/>
                </fieldset>
                <fieldset className='auth-form__block auth-form__block--center auth-form__block--margin'>
                    <button className='auth-form--button' type='submit'>Sign in</button>
                </fieldset>
            </form>
        )
    }
}

export default SignIn;
