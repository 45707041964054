import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ModelMain from './components/ModelMain/ModelMain';
import ModelBuy from './components/ModelBuy/ModelBuy';

class ModelContainer extends Component {
	static defaultProps = {
		name: "Account Receivable",
		tags : ["Machine learning", "Deep learning", "AI vs Machine learning", "Artificial intelligence", "AI"]
	}
	render () {
		return(
			<Switch>
				<Route path="/model/:id/buy" render={() => <ModelBuy {...this.props} />} />
				<Route path="/model/:id" render={() => <ModelMain {...this.props} />} />
			</Switch>
		)
	}
}
export default ModelContainer;
