import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AccountActions } from '../../../../services/actions';

import Products from './Products';

class ProductsContainer extends PureComponent {
    componentDidMount() {
        this.props.dispatch(AccountActions.getProductsList());
    }

    render() {
        return (
            <Products products={this.props.products.list}/>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.account.products
});

export default connect(mapStateToProps)(ProductsContainer);
