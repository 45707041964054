export const API_URL = {
    models: 'https://rwuz3hp9b8.execute-api.us-east-1.amazonaws.com/mock',
    availableModels: 'https://3g82ab7f1k.execute-api.us-east-1.amazonaws.com/default/portalAPIModel',
    buy_model: 'https://rwuz3hp9b8.execute-api.us-east-1.amazonaws.com/mock',
    dictionary: 'https://3g82ab7f1k.execute-api.us-east-1.amazonaws.com/default/portalAPIDictionary'
};

export const ENDPOINTS_API = {
    models_list: '/user-models',
    buy_model: '/buy-model'
};