import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './Overlay.css';

class Overlay extends PureComponent {
    static defaultProps = {
        inCenter: false,
		onClose: () => {}
    };

    render() {
        const {children, inCenter} = this.props;
        const contentClass = classNames('overlay-content', {
            'overlay-content--centered': inCenter
        });

        return (
            <div className='overlay'>
				<div className='close-modal' onClick={this.props.onClose} />
                {children}
            </div>
        );
    }
}

export default Overlay;
