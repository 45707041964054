import { API } from 'aws-amplify';
import { ENDPOINTS_API } from '../constants/endpoints';

export default class AccountActions {
    static ACCOUNT_STATE_RESET = 'ACCOUNT_STATE_RESET';

    static PRODUCTS_LIST_LOADING = 'PRODUCTS_LIST_LOADING';
    static PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS';
    static PRODUCTS_LIST_ERROR = 'PRODUCTS_LIST_ERROR ';

    static getProductsList() {
        return async dispatch => {
            dispatch({type: AccountActions.PRODUCTS_LIST_LOADING});
            return API.get('getUserProducts', ENDPOINTS_API.models_list, {}).then((responce) => {
                const models = responce.models;
                dispatch({type: AccountActions.PRODUCTS_LIST_SUCCESS, payload: models});
            }).catch((err) => {
                dispatch({type: AccountActions.PRODUCTS_LIST_ERROR, payload: err.message});
            });
        }
    }

    static reset() {
        return {
            type: AccountActions.ACCOUNT_STATE_RESET
        }
    }
}