import { API } from 'aws-amplify';
import { API_URL, ENDPOINTS_API } from '../constants/endpoints';
import axios from "axios";


export default class ModelsActions {
    static FETCH_MODELS_RESET = 'FETCH_MODELS_RESET';
    static FETCH_MODELS_REQUEST = 'FETCH_MODELS_REQUEST';
    static FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
    static FETCH_MODELS_ERROR = 'FETCH_MODELS_ERROR ';

    static BUY_MODEL_REQUEST = 'BUY_MODEL_REQUEST';
    static BUY_MODEL_SUCCESS = 'BUY_MODEL_SUCCESS';
    static BUY_MODEL_ERROR = 'BUY_MODEL_ERROR';


    static fetchModels(params) {
        return dispatch => {
            dispatch({type: ModelsActions.FETCH_MODELS_REQUEST});
            
            return axios.get(API_URL.availableModels, params)
                .then( response => dispatch({type: ModelsActions.FETCH_MODELS_SUCCESS, payload: response.data.model}))
                .catch( err => dispatch({type: ModelsActions.FETCH_MODELS_ERROR, payload: err.message}));
        }
    }

    static buyModel(model) {
        return dispatch => {
            dispatch({type: ModelsActions.BUY_MODEL_REQUEST});

            return API.get('buyModel', ENDPOINTS_API.buy_model, {
                queryStringParameters: {
                    model_id: 4,
                    price_unit_id: 1,
                    price_unit_quantity: 100
                }
            }).then(() => {
                dispatch({type: ModelsActions.BUY_MODEL_SUCCESS});
            }).catch((err) => {
                dispatch({type: ModelsActions.BUY_MODEL_ERROR, payload: err.message});
                throw err;
            });
        }
    }


    static reset() {
        return {type: ModelsActions.FETCH_MODELS_RESET};
    }


}