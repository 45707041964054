import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './ModelCard.css';

export default class ModelCard extends Component {
    static defaultProps = {
        name: 'No name',
        description: '',
        color: '#000000',
        url: '/model/financial-advisor-genome',
        isInternal: true
    };

    render() {
        let {description, isInternal} = this.props;
        return (
            <div className="model-card">
                {isInternal ?
                    <InternalLinkHeader {...this.props}/> :
                    <ExternalLinkHeader {...this.props}/>}
                <div className="model-description">{description}</div>
                <div className="model-footer">
                    {this.props.children}
                </div>
            </div>
        )
    }
}


export function ModelPreview(props) {
    return (
        <ModelCard {...props}>
            <div className="stars">153</div>
            <a href="http://172.31.9.129:3001/" className="link">Buy</a>
            <a href="http://172.31.9.129:3001/" className="link">Try</a>
        </ModelCard>
    );
}


const CardHeader = ({color, name}) => (
    <Fragment>
        <div style={{
            backgroundColor: color,
            backgroundImage: `url(https://picsum.photos/600/400/?image=${Math.floor(Math.random() * 600)})`,
            backgroundBlendMode: 'multiply'
        }} className="model-header-photo"/>
        <div className="model-header-content">
            <h2 className="model-name">{name}</h2>
            <div className="category-marker">
                <span className="short">IX</span>
                <span className="full">Insights<br/>Exchange</span>
            </div>
        </div>
    </Fragment>
);

const InternalLinkHeader = InternalLink(CardHeader);
const ExternalLinkHeader = ExternalLink(CardHeader);

function InternalLink(WrappedComp) {
    class HOC extends Component {
        render() {
            const {url} = this.props;
            return (
                <Link to={url} className='model-header'>
                    <WrappedComp {...this.props}/>
                </Link>
            );
        }
    }

    return HOC;
}

function ExternalLink(WrappedComp) {
    class HOC extends Component {
        render() {
            const {url} = this.props;
            return (
                <a href={url} className='model-header'>
                    <WrappedComp {...this.props}/>
                </a>
            );
        }
    }

    return HOC;
}

