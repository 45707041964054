import React from 'react';
import './Tags.css';

const Tags = ({tags = []}) => (
	<ul className="tags-wrapper">
		{tags.map((tag) =>
			<li key={tag}>{tag}</li>
		)}
	</ul>
)
export default Tags;
