import React, {Component} from 'react';
import {Link, NavLink, Route} from 'react-router-dom';
import Description from '../../tabs/Description';
import MoreInfo from '../../tabs/MoreInfo';

import './ModelMain.css';


class ModelMain extends Component {
	render () {
		let {match, tags, name} = this.props;
		return(
			<div className="model-main-wrapper">
				<ul className="breadcrumbs">
					<li className="step"><Link to="/">Marketplace</Link></li>
					<li className="step"><Link to="/">Insights exchange</Link></li>
					<li className="step">{name}</li>
				</ul>
				<header className="model-header">
					<h1 className="model-title">{name}</h1>
					<div className="toolbar-wrapper">
						<nav className="tabs-wrapper">
							<NavLink className="tab" activeClassName="active" to={`${match.url}`} exact>
								Description
							</NavLink>
							<NavLink className="tab" activeClassName="active" to={`${match.url}/more`}>
								More info
							</NavLink>
							<NavLink className="tab" activeClassName="active" to={`${match.url}/pricing`}>
								Pricing
							</NavLink>
							<NavLink className="tab" activeClassName="active" to={`${match.url}/how-to-use`}>
								How to use
							</NavLink>
							<NavLink className="tab" activeClassName="active" to={`${match.url}/case-studies`}>
								Case studies
							</NavLink>
						</nav>
						<div className="actions-wrapper">
							<button type="button" className="model-stars">
								<svg className="star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<path fill="none" stroke="#000" strokeWidth="2" d="M18 20.3l-.2-6.4 4-5L15.5 7 12 1.8 8.4 7 2.3 8.8l3.9 5-.2 6.5 6-2.2 6 2.2z"/>
								</svg>
								768
							</button>
							<Link className="buy-button" to={`${this.props.match.url}/buy`}>Get access</Link>
						</div>
					</div>
				</header>
				<main className="tab-content">
					<Route path={`${match.path}`} exact render={() => <Description tags={tags} />} />
					<Route path={`${match.path}/more`} render={() => <MoreInfo tags={tags} />} />
					<Route path={`${match.path}/pricing`} component={Pricing} />
					<Route path={`${match.path}/how-to-use`} component={HowToUse} />
					<Route path={`${match.path}/case-studies`} component={CaseStudies} />
				</main>
			</div>
		)
	}
}
export default ModelMain;


const Pricing = () => <h1>Pricing</h1>
const HowToUse = () => <h1>How to use</h1>
const CaseStudies = () => <h1>Case studies</h1>
