import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './Carousel.css';
import classNames from 'class-names';


export default class Carousel extends Component {
	static defaultProps =  {
		slides: [
			{
				skyline: "Insights Exchange",
				title: "Insights",
				subtitle: "but not as you know them",
				url: "insights"
			},
			{
				skyline: "Data Exchange",
				title: "Data",
				subtitle: "but not as you know them",
				url: "data"
			},
			{
				skyline: "Outcomes Exchange",
				title: "Outcomes",
				subtitle: "but not as you know them",
				url: "outcomes"
			}
		]
	};
	state = {
		activeSlide: 0,
		animation: null
	}
	nextSlide = () => {
		let {slides} = this.props;
		let {activeSlide, animation} = this.state;
		let prevSlide = activeSlide;
		if (animation) return;
		activeSlide = (activeSlide < slides.length-1) ? activeSlide+1 : 0;
		this.setState({
			activeSlide,
			prevSlide,
			animation: "next"
		})
	}
	prevSlide = () => {
		let {slides} = this.props;
		let {activeSlide, animation} = this.state;
		let prevSlide = activeSlide;
		if (animation) return;
		activeSlide = (activeSlide > 0) ? activeSlide-1 : slides.length-1;
		this.setState({
			activeSlide,
			prevSlide,
			animation: "prev"
		})
	}
	onAnimationEnd = (e) => {
		if (e.animationName === "inNext" || e.animationName === "inPrev") {
			this.setState({
				animation: null
			})
		}
	}

	render () {
		let {slides} = this.props;
		let {activeSlide, prevSlide, animation} = this.state;
		return(
			<section className="main-carousel">
				<button type="button" onClick={this.prevSlide} className="slide-prev" />
				<button type="button" onClick={this.nextSlide} className="slide-next" />
				<ul onAnimationEnd={this.onAnimationEnd} className="slides">
					{slides.map((slide, id) => (
						<li key={slide.title} className={classNames({
							"slide-wrapper": true,
							"active": id === activeSlide,
							"inNext": id === activeSlide && animation === "next",
							"inPrev": id === activeSlide && animation === "prev",
							"last": id === prevSlide,
							"outNext": id === prevSlide && animation === "next",
							"outPrev": id === prevSlide && animation === "prev"
						})}>
							<span className="sky-line">{slide.skyline}</span>
							<div className="content">
								<h1 className="main-title">{slide.title}</h1>
								<p className="sub-title">{slide.subtitle}</p>
							</div>
							<Link to={`/exchange/${slide.url}`} className="more-link">Find out more</Link>
						</li>
					))}
				</ul>
			</section>
		)
	}
}
