import React, {Component} from 'react';
import Select from 'react-select';

const styles = {
    container: (style) => ({
        ...style,
        outline: 0,
        display: 'flex'
    }),
    control: (style, {isFocused}) => ({
        display: 'flex',
        alignItems: 'center',
        color: isFocused ? 'var(--accent)' : 'currentColor',
        cursor: 'pointer',
        transition: 'color 200ms ease-out',
        ':hover': {
            color: isFocused ? 'var(--accent)' : 'currentColor'
        }
    }),
    valueContainer: (style) => ({
        display: 'flex',
        order: 1,
        flex: '0 0 auto',
        padding: '10px 0 10px 0',
    }),
    singleValue: (style) => ({
        whiteSpace: 'nowrap',
    }),
    indicatorsContainer: (style, {selectProps}) => ({
        flex: '0 0 auto',
        ':before': {
            content: "''",
            display: 'block',
            border: '0.4em solid currentColor',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            borderRight: 'none',
            borderLeftWidth: '0.75em',
            marginRight: '0.5em',
            transition: 'transform 100ms ease-out',
            transform: selectProps.menuIsOpen ? 'rotate(90deg) scale(0.7)' : '',
        }
    }),
    indicatorSeparator: (style) => ({
        display: 'none'
    }),
    dropdownIndicator: (style) => ({
        display: 'none',
    }),
    menu: (style) => ({
        minWidth: '120px',
        top: '100%',
        marginTop: '-0.5em',
        position: 'absolute',
        zIndex: '1',
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        boxShadow: '0 2px 6px rgba(0,0,0,0.4)'
    }),
    menuList: style => {
    },
    option: (style, {isFocused}) => ({
        padding: '0.5em 1em',
        whiteSpace: 'nowrap',
        background: isFocused ? 'black' : 'transparent',
        color: isFocused ? 'white' : 'black',
        cursor: 'pointer',
    })
};

export default props => <Select styles={styles} {...props} />;
