import React, {Component, Fragment} from 'react';
import Carousel from '../../components/Carousel/Carousel';
import IndustrySelector from '../../components/IndustrySelector/IndustrySelector';
import { ModelPreview } from '../../components/ModelCard/ModelCard';
import './Home.css';
import connect from "react-redux/es/connect/connect";

let colors = [
    "#507FFB", "#9171FB", "#DE507A", "#74B184", "#5C969C", "#DF5797", 
    "#5E5097", "#9A5E9D", "#E69143", "#73CC8B", "#F2CF34", "#E2771C", 
    "#3A60CA", "#EEBC3E", "#CF0141", "#AA2959", "#A5888E", "#79D69C",
];

class Home extends Component {
    getCards() {
        return this.props.models.map((model, id) => (
            <ModelPreview
                key={id}
                name={model.name}
                description={model.description}
                color={colors[Math.floor(Math.random() * colors.length)]}
            />)
        );
    }

    render() {
        return (
            <Fragment>
                <Carousel/>
                <div className="main-industry-selector">
                    <IndustrySelector openUp={true}/>
                </div>
                <section className="products-wrapper">
                    <h1 className="main-title">Popular products</h1>
                    <div className="cards-wrapper">
                        {this.getCards()}
                    </div>
                </section>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        models: state.models.list
    }
}

export default connect(mapStateToProps, {/*Actions*/})(Home);
