import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {FilterSelect, IndustrySelector} from '../../components';
import {ModelPreview} from '../../components/ModelCard/ModelCard';
import './Industry.css';
import connect from "react-redux/es/connect/connect";
import {DictionaryActions} from "../../services/actions";

let colors = [
    "#507FFB",
    "#9171FB",
    "#DE507A",
    "#74B184",
    "#5C969C",
    "#DF5797",
    "#5E5097",
    "#9A5E9D",
    "#E69143",
    "#73CC8B",
    "#F2CF34",
    "#E2771C",
    "#3A60CA",
    "#EEBC3E",
    "#CF0141",
    "#AA2959",
    "#A5888E",
    "#79D69C",
];

class Industry extends Component {
    state = {
        filters: {
            modelType: null,
            solutionType: null,
            country: null
        }
    };

    render() {
        const {country, model_type, solution_type, industry} = this.props.dictionary;
        let industryId = parseInt(this.props.match.params.id);
        let selectedIndustry = industry.filter(e => e.id === industryId);

        if (selectedIndustry.length > 0) {
            selectedIndustry = selectedIndustry[0];
        } else {
            selectedIndustry = industry[0]; //silently fallback to the first available industry 
        }

        return (
            <div>
                <ul className="breadcrumbs">
                    <li className="step"><Link to="/">Marketplace</Link></li>
                    <li className="step"><Link to="/">Industry</Link></li>
                    <li className="step">{selectedIndustry.name}</li>
                </ul>
                <div className="industry-search-wrapper">
                    <h1 className="industry-search-input">{selectedIndustry.name}</h1>
                    <IndustrySelector label="Select another industry"/>
                </div>
                <main className="industry-main-wrapper">
                    <div className="filters-wrapper">
                        <h2 className="title">Filters</h2>
                        <FilterSelect
                            className="filter" isSearchable={false}
                            value={{value: null, label: "Category"}}
                            defaultValue={{value: null, label: "Category"}}
                            onChange={(e) => {
                                this.setState({filters: {...this.state.filters, modelType: e.value}})
                            }}
                            options={model_type.map(e => {
                                return {value: e.id, label: e.name}
                            }).concat({value: null, label: "Clear filter"})}
                        />
                        <FilterSelect
                            className="filter"
                            isSearchable={false}
                            value={{value: null, label: "Solution type"}}
                            defaultValue={{value: null, label: "Solution type"}}
                            onChange={(e) => {
                                this.setState({filters: {...this.state.filters, solutionType: e.value}})
                            }}
                            options={solution_type.map(e => {
                                return {value: e.id, label: e.name}
                            }).concat({value: null, label: "Clear filter"})}
                        />
                        <FilterSelect
                            className="filter"
                            isSearchable={false}
                            value={{value: null, label: "Country"}}
                            defaultValue={{value: null, label: "Country"}}
                            onChange={(e) => {
                                this.setState({filters: {...this.state.filters, country: e.value}})
                            }}
                            options={country.map(e => {
                                return {value: e.id, label: e.name}
                            }).concat({value: null, label: "Clear filter"})}
                        />
                    </div>
                    <div className="actions-bar">
                        <div className="counter">10 Products found</div>
                        <FilterSelect
                            className="order"
                            isSearchable={false}
                            value={{value: null, label: "Recent first"}}
                            defaultValue={{value: null, label: "Recent first"}}
                            onChange={(e) => console.log(e)}
                            options={[
                                {value: 1, label: "First"},
                                {value: 2, label: "Second"},
                            ]}
                        />
                    </div>
                    <div className="cards-wrapper">
                        {
                            this.props.models
                                .filter(e => {
                                    let {modelType, solutionType, country} = this.state.filters;
                                    return e.industry_id === selectedIndustry.id && 
                                        (modelType === null || modelType === e.model_type_id) && 
                                        (solutionType === null || solutionType === e.solution_type_id) && 
                                        (country === null || country === e.country_id)
                                })
                                .map((model, id) => (
                                        <ModelPreview
                                            key={id}
                                            name={model.name}
                                            description={model.description}
                                            color={colors[Math.floor(Math.random() * colors.length)]}
                                        />
                                    )
                                )
                        }
                    </div>
                </main>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        models: state.models.list,
        dictionary: {...state.dictionary}
    };
}

export default withRouter(connect(mapStateToProps, {fetchDictionary: DictionaryActions.fetchDictionary})(Industry));