import { combineReducers } from 'redux';
import account from './UserReducer';
import models from './ModelsReducer';
import dictionary from './DictionaryReducer';

const rootReducer = combineReducers({
    account,
    models,
    dictionary
});

export default rootReducer;